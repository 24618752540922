import { sendUiEvent, useAppCtxSelector, useAppStateMatches } from '@/core';
import { useEffect, useMemo, useState } from 'react';
import { appSectionsById, Intercept, rolesById } from '@knapsack/types';
import {
  ButtonGroup,
  ButtonPrimary,
  componentSpacing,
  Flex,
  Icon,
  InputCheckbox,
  TextBody,
  TextHeading,
} from '@knapsack/toby';
import { TextLink } from '@/components/text-link';
import { DOCS_URLS } from '@knapsack/core';
import { useKsLocation } from '@/utils/ks-location.hooks';
import {
  getPreventInterceptCookie,
  setPreventInterceptCookie,
  showIntercept,
} from '@/core/utils/intercepts';

export const useInterceptReadOnlySection = () => {
  const hasUserAndSite = useAppStateMatches(
    'usersAndSite.siteLoaded.loggedInUser.allInfo',
  );
  const siteIsEditable = useAppStateMatches(
    'site.loaded.appClient.viewable.editable',
  );
  const { userId, userRole } = useAppCtxSelector(({ site, user }) => {
    return {
      userId: user?.userId,
      userRole: user?.getSiteRole(site?.meta?.siteId),
    };
  });
  const { section } = useKsLocation();
  const roleInfo = useMemo(() => rolesById[userRole], [userRole]);

  const [dontShowAgain, setDontShowAgain] = useState<boolean>(false);

  useEffect(() => {
    if (!hasUserAndSite || !siteIsEditable) return;
    if (!section || section === 'ROOT') return;
    if (roleInfo?.sectionAccess[section]?.canEdit) return;
    if (!roleInfo?.sectionAccess[section]?.canView) return;

    const preventInterceptCookie = getPreventInterceptCookie({ userId });
    const interceptType: Intercept = `readOnlyIntercept${appSectionsById[section]}`;

    if (preventInterceptCookie?.[interceptType]) return;

    showIntercept({
      type: interceptType,
      content: {
        title: (
          <Flex align="center" gap="small">
            <Icon symbol="state-warning" color="warning" />
            <TextHeading tag="h2" size="small" spacing="none">
              {appSectionsById[section]} Editing Disabled
            </TextHeading>
          </Flex>
        ),
        body: (
          <TextBody className={componentSpacing({ paddingBottom: 'xlarge' })}>
            As a {roleInfo.title}, you can view this section, but editing isn’t
            enabled for your role.{' '}
            <TextLink href={DOCS_URLS.TEAM_MANAGEMENT} external>
              Learn more about roles.
            </TextLink>
          </TextBody>
        ),
        footer: (
          <ButtonGroup justify="spaceBetween">
            <InputCheckbox
              label="Don’t show me again"
              onChange={(value) => {
                setDontShowAgain(value);
              }}
              value={dontShowAgain}
            />
            <ButtonPrimary
              onTrigger={() => {
                if (dontShowAgain) {
                  setPreventInterceptCookie({
                    intercept: interceptType,
                    userId,
                  });
                }
                sendUiEvent({ type: 'modal.triggerClose' });
              }}
              label="Dismiss"
              testId={`${interceptType}--dismiss`}
            />
          </ButtonGroup>
        ),
      },
    });
  }, [
    dontShowAgain,
    hasUserAndSite,
    roleInfo,
    section,
    siteIsEditable,
    userId,
  ]);
};
