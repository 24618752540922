import type { Intercept } from '@knapsack/types';
import { sendUiEvent } from '../xstate';
import type { ModalProps } from '../layout/modal';

export type PreventInterceptCookie = {
  [K in Intercept]?: Date;
};

export type InterceptProps = {
  type: Intercept;
  content: Pick<ModalProps, 'title' | 'subtitle' | 'body' | 'footer' | 'size'>;
};

function getPreventInterceptCookieKey({ userId }: { userId: string }): string {
  return `ks::preventIntercepts::${userId}`;
}

export function getPreventInterceptCookie({
  userId,
}: {
  userId: string;
}): PreventInterceptCookie | undefined {
  const preventInterceptCookieKey = getPreventInterceptCookieKey({
    userId,
  });
  const preventInterceptCookie = window.localStorage.getItem(
    preventInterceptCookieKey,
  );
  return preventInterceptCookie
    ? JSON.parse(preventInterceptCookie)
    : undefined;
}
export function setPreventInterceptCookie({
  intercept,
  userId,
}: {
  intercept: Intercept;
  userId: string;
}) {
  const preventInterceptCookieKey = getPreventInterceptCookieKey({
    userId,
  });
  const preventInterceptCookie = getPreventInterceptCookie({ userId });
  const dateSet = new Date();

  if (preventInterceptCookie) {
    const updatedCookie = {
      ...preventInterceptCookie,
      [intercept]: dateSet,
    };
    window.localStorage.setItem(
      preventInterceptCookieKey,
      JSON.stringify(updatedCookie),
    );
  } else {
    const newPreventInterceptCookie: Partial<PreventInterceptCookie> = {
      [intercept]: dateSet,
    };
    window.localStorage.setItem(
      preventInterceptCookieKey,
      JSON.stringify(newPreventInterceptCookie),
    );
  }
}

export function showIntercept({
  type,
  content: { body, footer, title, subtitle, size },
}: InterceptProps) {
  sendUiEvent([
    {
      type: 'modal.setContent',
      modal: {
        title,
        subtitle,
        body,
        footer,
        size,
        preventFocusTrap: true,
        testId: type,
      },
    },
    'modal.triggerOpen',
  ]);
}
