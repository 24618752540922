import { useAppCtxSelector, useAppStateMatches } from '@/core';
import { useEffect } from 'react';
import { showIntercept } from '@/core/utils/intercepts';
import { ProfileForm } from '../components/profile-form';

export const useInterceptProfileIncomplete = () => {
  const hasUserAndSite = useAppStateMatches(
    'usersAndSite.siteLoaded.loggedInUser.allInfo',
  );
  const siteId = useAppCtxSelector((ctx) => ctx.site?.meta?.siteId);
  const user = useAppCtxSelector((ctx) => ctx.user);

  useEffect(() => {
    if (!hasUserAndSite) return;
    if (!user?.membershipSiteIds?.includes(siteId)) return;
    if (user?.info?.responsibilityId) return;
    showIntercept({
      type: 'profileIncompleteIntercept',
      content: {
        title: 'Welcome Back',
        subtitle: "Let's make sure your profile is up to date.",
        body: <ProfileForm />,
        size: 'wide',
      },
    });
  }, [hasUserAndSite, siteId, user]);
};
