'use client';

/**
 * @file
 * App Wide hooks run here. This does not wrap the entire app so re-renders are isolated.
 * That's why this is a component that renders nothing.
 */

import { sendUiEvent } from '@/core';
import { useAppGqlSubscriptions } from '@/core/utils/use-app-gql-subscriptions';
import { useHashLinkHandler } from '@/core/utils/use-hash-link-handler';
import { useActiveTopNavItem } from '@/domains/navs/hooks';
import {
  useInterceptProfileIncomplete,
  useInterceptReadOnlySection,
} from '@/domains/users/intercepts';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, ReactNode } from 'react';

function useUsingKeyboard() {
  useEffect(() => {
    const className = 'is-using-keyboard';
    let isUsingKeyboard = false;
    const keyboardListener = () => {
      if (isUsingKeyboard) return;
      isUsingKeyboard = true;
      document.body.classList.add(className);
    };
    const mouseListener = () => {
      if (!isUsingKeyboard) return;
      isUsingKeyboard = false;
      document.body.classList.remove(className);
    };
    document.addEventListener('keydown', keyboardListener);
    document.addEventListener('mousedown', mouseListener);
    return () => {
      document.removeEventListener('keydown', keyboardListener);
      document.removeEventListener('mousedown', mouseListener);
    };
  }, []);
}

function useRouteWatcher() {
  const pathname = usePathname();
  const search = useSearchParams();
  const path = `${pathname}${search.size > 0 ? `?${search.toString()}` : ''}`;
  useEffect(() => {
    sendUiEvent({ type: 'nav.locationChanged', path });
  }, [path]);
}

function useTopNavItemWatcher() {
  const activeTopNavItem = useActiveTopNavItem();
  useEffect(() => {
    sendUiEvent({ type: 'nav.resetExpandedNavIds' });
  }, [activeTopNavItem?.id]);
}

export const AppHooks = (): ReactNode => {
  useRouteWatcher();
  useInterceptProfileIncomplete();
  useInterceptReadOnlySection();
  useAppGqlSubscriptions();
  useUsingKeyboard();
  useHashLinkHandler();
  useTopNavItemWatcher();

  return null;
};
