import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
  ApolloLink,
  HttpLink,
  from,
  split,
  Operation,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { ksUrls } from './ks-urls';
import { getHttpHeaders } from './http-headers-utils';

export { ApolloProvider, useQuery, gql };

function isOperationSubscription(op: Operation): boolean {
  const definition = getMainDefinition(op.query);
  const isSubscription =
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription';
  // console.debug(`isOperationSubscription(${isSubscription})`, op);
  return isSubscription;
}

/**
 * Apollo Client "pipes" operations through several "Links" for each request.
 */
function getLinks(): ApolloLink {
  const retryLink = new RetryLink();
  const httpLink = new HttpLink({ uri: ksUrls.dbGqlEndpoint });

  const wsLink = new WebSocketLink({
    uri: ksUrls.dbGqlWsEndpoint,
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: async () => {
        const headers = await getHttpHeaders({
          hasuraClientName: 'app-ui--apollo',
        });
        // console.log(`connectionParams headers`, headers);
        return {
          headers,
        };
      },
    },
  });

  return from([
    retryLink,
    setContext(async (operation, prevContext) => {
      const headers = await getHttpHeaders({
        hasuraClientName: 'app-ui--apollo',
      });
      const context = {
        ...prevContext,
        headers,
      };
      // console.debug(`gql context`, context, operation);
      return context;
    }),
    split(
      // We "split" the traffic based on this boolean
      isOperationSubscription,
      // if `true`
      wsLink,
      // if `false`
      httpLink,
    ),
  ]);
}

export const client = new ApolloClient({
  cache: new InMemoryCache({
    /**
     * https://www.apollographql.com/docs/react/caching/cache-configuration/#generating-unique-identifiers
     */
    typePolicies: {
      siteUsers: {
        keyFields: ['siteId', 'userId'],
      },
    },
  }),
  link: getLinks(),
});

export function reloadApolloClient(): void {
  client.setLink(getLinks());
}

export const resetApolloClientStore = client.resetStore;
